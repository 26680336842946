import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { FacebookShareButton, TwitterShareButton } from 'react-share';

import useOptimize from '../../../hooks/use-optimize';
import { getTrackingLink } from '../../../helpers/trackingLink';

import Layout from '../../layout/layout';
import SEO from '../../meta/seo';
import PostList from './list';
import PostCard from './card';
import PostContent from './content';

const Post = ({ data }) => {
  const location = useLocation();

  const copyRef = useRef();
  useOptimize(() => {
    if (copyRef.current) {
      copyRef.current
        .querySelectorAll('[href^="https://x.bndspn.com/"]')
        .forEach(async (element) => {
          const href = element.href;
          const anonymousLink = new URL(href);
          const link = await getTrackingLink(
            anonymousLink.searchParams.get('campaign'),
            'apple'
          );
          // Update only if the DOM hasn't changed while we were waiting for the tracking link
          if (element.href == href) {
            element.href = link;
          }
        });
    }
  }, [copyRef.current]);

  const { post, previousPosts, nextPosts } = data;
  const relatedArticles = [
    ...(previousPosts.edges.length && nextPosts.edges.length == 3
      ? nextPosts.edges.slice(1)
      : nextPosts.edges),
    ...previousPosts.edges,
  ];

  return (
    <Layout campaign="blog">
      <SEO
        title={post.frontmatter.meta?.title || post.frontmatter.title}
        description={post.frontmatter.meta?.description}
        facebookImage={post.frontmatter.coverImage.childImageSharp.facebook.src}
        twitterImage={post.frontmatter.coverImage.childImageSharp.twitter.src}
      />

      <article className="container space-around">
        {/* Content */}
        <PostContent data={post} />

        {/* Share buttons */}
        <div className="max-w-content mx-auto text-center mb-6">
          <h3 className="SB24 md:SB32 mb-6">
            Enjoyed our writing?
            <br />
            Share it!
          </h3>
          <div className="flex space-x-6 justify-center items-center">
            <FacebookShareButton
              resetButtonStyle={false}
              className="btn btn--social-share text-white bg-social-facebook"
              url={location.href}
              hashtag="#spliceapp"
            >
              Facebook
            </FacebookShareButton>
            <TwitterShareButton
              resetButtonStyle={false}
              className="btn btn--social-share text-white bg-social-twitter"
              url={location.href}
              hashtags={['#spliceapp']}
            >
              Twitter
            </TwitterShareButton>
          </div>
        </div>
      </article>

      {/* Related articles */}
      <section className="container space-around">
        <PostList>
          {relatedArticles.slice(0, 3).map((edge, index, edges) => (
            <PostCard
              data={edge}
              key={edge.node.id}
              className={index == edges.length - 1 ? 'md:hidden lg:block' : ''}
            />
          ))}
        </PostList>
      </section>
    </Layout>
  );
};

Post.propTypes = {
  data: PropTypes.shape({
    post: PropTypes.shape({
      html: PropTypes.string,
      frontmatter: PropTypes.shape({
        title: PropTypes.string,
        date: PropTypes.any,
        coverImage: PropTypes.any,
        meta: PropTypes.object,
      }),
    }),
    nextPosts: PropTypes.shape({
      edges: PropTypes.array,
    }),
    previousPosts: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default Post;

export const query = graphql`
  query PostQuery(
    $slug: String!
    $previousSkip: Int!
    $previousLimit: Int!
    $nextSkip: Int!
    $nextLimit: Int!
  ) {
    post: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      ...PostContent
      frontmatter {
        title
        coverImage {
          childImageSharp {
            facebook: fixed(width: 1200, height: 630, quality: 90) {
              ...GatsbyImageSharpFixed
            }
            twitter: fixed(width: 1024, height: 512, quality: 90) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        meta {
          title
          description
        }
      }
    }
    nextPosts: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/posts/" } }
      sort: { fields: frontmatter___date, order: DESC }
      skip: $nextSkip
      limit: $nextLimit
    ) {
      edges {
        node {
          id
        }
        ...PostCard
      }
    }
    previousPosts: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/posts/" } }
      sort: { fields: frontmatter___date, order: DESC }
      skip: $previousSkip
      limit: $previousLimit
    ) {
      edges {
        node {
          id
        }
        ...PostCard
      }
    }
  }
`;
