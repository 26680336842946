import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

// Image is a preview-ready component
import Image from '../../common/image';

const PostContent = ({ data }) => {
  const { postContentHTML: html, postContentFrontmatter: frontmatter } = data;
  return (
    <>
      <div className="text-center max-w-content mx-auto mb-8 md:mb-16">
        <h3 className="M16UP lg:M24UP mb-4 text-gray-middle">
          {frontmatter.date}
        </h3>
        <h1 className="text-title">{frontmatter.title}</h1>
      </div>

      <Image
        className="mb-8 md:mb-16 max-w-hero mx-auto rounded-4xl md:rounded-5xl shadow-card-dark"
        image={frontmatter.coverImage}
        alt={frontmatter.title}
      />

      {/* Support netlify CMS preview */}
      {typeof html == 'string' ? (
        <div
          className="max-w-content mx-auto text-prose M16 md:M18 mb-12"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      ) : (
        <div className="max-w-content mx-auto text-prose M16 md:M18 mb-12">
          {html}
        </div>
      )}
    </>
  );
};

PostContent.propTypes = {
  data: PropTypes.shape({
    postContentHTML: PropTypes.string,
    postContentFrontmatter: PropTypes.shape({
      title: PropTypes.string,
      date: PropTypes.string,
      coverImage: PropTypes.any,
    }),
  }),
};

export default PostContent;

export const query = graphql`
  fragment PostContent on MarkdownRemark {
    postContentHTML: html
    postContentFrontmatter: frontmatter {
      title
      date(formatString: "D MMMM YYYY")
      coverImage {
        childImageSharp {
          fluid(maxWidth: 1504, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;
